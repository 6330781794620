<template>
  <div v-if="loaded">
    <div class="padding-x-4 padding-x-5-tablet">
      <div class="columns is-centered padding-y-2 padding-y-0-tablet is-mobile">
        <div class="column is-narrow">
          <b-field
            label="Connection Name"
            class="has-text-centered is-size-5 required"
            :type="{'is-danger': errors.has('name')}"
            :message="errors.first('name')"
            style="width: 250px;">
            <b-input
              v-model="connection.name"
              v-validate="'required'"
              size="is-medium"
              name="name"
              data-vv-as="name" />
          </b-field>
        </div>
        <div class="column is-narrow">
          <b-field label="Type" class="has-text-centered is-size-5 required">
            <b-select v-model="connection.type" size="is-medium">
              <option value="TubeAndCoupling">
                Premium Tube &amp; Coupling
              </option>
              <option value="SemiPremiumTubeAndCoupling">
                Semi-premium Tube &amp; Coupling
              </option>
              <option value="SemiFlush">
                Semi Flush
              </option>
              <option value="PlainEnd">
                Plain End
              </option>
            </b-select>
          </b-field>
        </div>
      </div>

      <div class="has-text-centered">
        <div class="card" style="display: inline-flex; margin: 0 auto">
          <div class="card-header">
            <div class="card-header-title" style="justify-content: center">
              Availability <span style="width: 7px" /> <b-tooltip label="Show/hide this connection on different modules" position="is-top" type="is-dark">
                <b-icon icon="info-circle" size="is-small" />
              </b-tooltip>
            </div>
          </div>
          <div class="card-content" style="font-size: 13px; line-height: 1.1">
            <div class="columns">
              <div class="column">
                <div><b-switch v-model="connection.royaltyAvailability" true-value="1" false-value="0" style="padding-left: 0.5em; margin-bottom: 5px;;" /></div>
                Royalty Orders
              </div>
              <div class="column">
                <div><b-switch v-model="connection.datasheetAvailability" true-value="1" false-value="0" style="padding-left: 0.5em; margin-bottom: 5px;" /></div>
                Datasheets
              </div>
              <div class="column">
                <div><b-switch v-model="connection.gaugeAvailability" true-value="1" false-value="0" style="padding-left: 0.5em; margin-bottom: 5px;" /></div>
                Inspection Sheets
              </div>
              <div class="column">
                <div><b-switch v-model="connection.blankingAvailability" true-value="1" false-value="0" style="padding-left: 0.5em; margin-bottom: 5px;" /></div>
                Blanking Dimensions
              </div>
              <div class="column">
                <div><b-switch v-model="connection.technicalDocsAvailability" true-value="1" false-value="0" style="padding-left: 0.5em; margin-bottom: 5px;" /></div>
                Technical Documents
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="is-hidden-mobile">
      <div class="columns" style="margin-bottom: 30px">
        <div v-for="image in orderBy(connection.images, 'id')" :key="image.id" class="column is-flex" style="flex-direction: column">
          <div class="section-title mb-2">
            {{ image.name }}
          </div>
          <b-field
            class="card equal-height"
            style="border:none; background: none"
            :type="{'is-danger': errors.has('imageUp')}"
            :message="errors.first('imageUp')">
            <b-upload
              drag-drop
              class="has-background-white"
              name="imageUp"
              data-vv-as="imageUp"
              @input="uploadFile(image, $event)">
              <section class="padding-xy-3">
                <div class="content has-text-centered">
                  <div v-if="image.data || image.screenshotUrl">
                    <img v-if="image.data" :src="image.data" style="max-height: 150px">
                    <img v-else :src="image.screenshotUrl" style="max-height: 150px">
                  </div>
                  <div v-else>
                    <p>
                      <b-icon icon="upload" size="is-large" type="is-grey" />
                    </p>
                    <b class="has-text-grey-light">Upload {{ image.name }}</b>
                  </div>
                </div>
              </section>
            </b-upload>
          </b-field>
          <!-- <span v-if="image.name !== 'Rendering'">
            <b-field
              class="card equal-height"
              style="border: none; background: none"
              :type="{'is-danger': errors.has('imageUp')}"
              :message="errors.first('imageUp')">
              <div class="section-title">
                {{ image.name }}
              </div>
              <section class="padding-xy-3">
                <div class="content has-text-centered">
                  <img :src="getRenderImg(image.connectionId)" style="max-height: 150px">
                </div>
              </section>
            </b-field>
          </span> -->
        </div>
      </div>
    </div>

    <div class="padding-x-0 padding-x-5-tablet">
      <template v-if="profile.hasJfeDataManagerPermission">
        <div class="padding-x-4 padding-x-0-tablet section-title is-size-4">
          <div class="columns is-vcentered is-mobile">
            <div class="column">
              Sizes
            </div>
            <div class="column is-narrow">
              <b-button
                icon-left="plus"
                type="is-primary"
                rounded
                @click="addSizeToggle = !addSizeToggle; toggleSizesRow(0); sizeExists = false">
                Add Size
              </b-button>
            </div>
          </div>
        </div>
        <div
          v-if="addSizeToggle"
          class="columns is-vcentered has-background-grey-lighter is-multiline is-marginless">
          <b-message
            v-if="sizeExists"
            class="column is-12 is-paddingless"
            type="is-danger">
            This size already exists
          </b-message>

          <div class="column is-narrow">
            <b-field class="required" :type="{'is-danger': sizeExists}">
              <b-input v-model="newSize" placeholder="Size" />
              <p class="control">
                <span class="button is-static">in</span>
              </p>
            </b-field>
          </div>
          <div class="column is-narrow">
            <b-button class="is-success" @click="addSize">
              Add Size
            </b-button>
          </div>
        </div>
        <b-table
          ref="sizesTable"
          class="nested_rows no-header"
          :class="openedSizeRow.length ? 'dim' : ''"
          :mobile-cards="false"
          :data="sizesFiltered"
          detailed
          custom-detail-row
          :opened-detailed="openedSizeRow"
          detail-key="size"
          :row-class="(row, index) => (row.size == openedSizeRow ? 'is-open' : '')"
          @click="(row, index) => closeOtherRows(row, index)"
          @details-open="(row, index) => closeOtherRows(row, index)">
          <b-table-column key="size" v-slot="props" field="size" label="Size">
            {{ props.row.size }}"
          </b-table-column>
          <b-table-column custom-key="spacer" />
          <b-table-column key="actions" v-slot="props" cell-class="has-text-right" field="actions" label="Actions">
            <b-dropdown
              v-if="props.row.size == openedSizeRow"
              :mobile-modal="false"
              position="is-bottom-left">
              <b-button
                slot="trigger"
                rounded
                type="is-dark is-transparent is-small"
                icon-right="ellipsis-h"
                class="icon-only"
                style="margin-right: 15px" />
              <b-dropdown-item @click="deleteSize(props.row)">
                Delete Size
              </b-dropdown-item>
            </b-dropdown>

            <b-button
              v-if="props.row.size == openedSizeRow"
              class="is-small is-borderless"
              icon-left="plus"
              @click="weightModal(props.row, initialWeight())">
              Add Weight
            </b-button>
            <b-button
              v-else
              class="is-outlined is-borderless is-small"
              style="margin-right: 5px"
              icon-left="pencil"
              @click.stop="sizeModal(props.row)" />
          </b-table-column>

          <template slot="detail" slot-scope="props">
            <tr
              v-for="weight in props.row.weights"
              :key="weight.sizeId + weight.weight"
              class="child_row"
              @click="weightModal(props.row, weight)">
              <td />
              <td colspan="1" style="padding-left: 0">
                <div class="align-center">
                  <b-button
                    class="is-outlined is-borderless is-small"
                    :class="weight.deleted ? 'is-danger' : 'is-primary'"
                    style="margin-right: 5px"
                    icon-left="pencil" />
                  <span>{{ weight.weight }}#</span>
                </div>
              </td>
              <td />
            </tr>
          </template>
        </b-table>
      </template>
      <template v-if="profile.hasJfeRoyaltyTrackerPermission">
        <hr>
        <div class="padding-x-4 padding-x-0-tablet section-title is-size-4">
          Pricing
        </div>
        <div class="b-table scrollable license_editor">
          <div class="table-wrapper">
            <table class="table" style="border: solid 1px #eee; border-left: none">
              <thead>
                <tr>
                  <th width="23%" class="has-text-centered">
                    Size
                  </th>
                  <th class="has-text-centered">
                    Manufacture
                  </th>
                  <th class="has-text-centered">
                    Repair
                  </th>
                  <th class="has-text-centered">
                    Accessory
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="sizesFiltered.length" class="mini_header">
                  <td />
                  <td>
                    <b-field grouped>
                      <b-field label="CRA">
                        <b-input />
                      </b-field>
                      <b-field label="Non">
                        <b-input />
                      </b-field>
                    </b-field>
                  </td>
                  <td>
                    <b-field grouped>
                      <b-field label="CRA">
                        <b-input />
                      </b-field>
                      <b-field label="Non">
                        <b-input />
                      </b-field>
                    </b-field>
                  </td>
                  <td>
                    <b-field grouped>
                      <b-field label="CRA">
                        <b-input />
                      </b-field>
                      <b-field label="Non">
                        <b-input />
                      </b-field>
                    </b-field>
                  </td>
                </tr>
              </tbody>
              <tbody v-for="(size, index) in sizesFiltered" :key="index">
                <tr>
                  <td>
                    <span>{{ size.size }}"</span>
                  </td>
                  <td>
                    <b-field grouped>
                      <b-field class="required" :type="{'is-danger': errors.has('priceManCra' + index)}">
                        <b-input v-model.number="size.priceManCra" v-validate="'required'" expanded size="is-small" :name="'priceManCra' + index" data-vv-as="Price CRA" />
                      </b-field>
                      <b-field class="required" :type="{'is-danger': errors.has('priceManNon' + index)}">
                        <b-input v-model.number="size.priceManNon" v-validate="'required'" expanded size="is-small" :name="'priceManNon' + index" data-vv-as="Price Non" />
                      </b-field>
                    </b-field>
                  </td>
                  <td>
                    <b-field grouped>
                      <b-field class="required" :type="{'is-danger': errors.has('priceRepCra' + index)}">
                        <b-input v-model.number="size.priceRepCra" v-validate="'required'" expanded size="is-small" :name="'priceRepCra' + index" data-vv-as="Price CRA" />
                      </b-field>
                      <b-field class="required" :type="{'is-danger': errors.has('priceRepNon' + index)}">
                        <b-input v-model.number="size.priceRepNon" v-validate="'required'" expanded size="is-small" :name="'priceRepNon' + index" data-vv-as="Price Non" />
                      </b-field>
                    </b-field>
                  </td>
                  <td>
                    <b-field grouped>
                      <b-field class="required" :type="{'is-danger': errors.has('priceAccCra' + index)}">
                        <b-input v-model.number="size.priceAccCra" v-validate="'required'" expanded size="is-small" :name="'priceAccCra' + index" data-vv-as="Price CRA" />
                      </b-field>
                      <b-field :type="{'is-danger': errors.has('priceAccNon' + index)}">
                        <b-input v-model.number="size.priceAccNon" v-validate="'required'" expanded size="is-small" :name="'priceAccNon' + index" data-vv-as="Price Non" />
                      </b-field>
                    </b-field>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </div>
    <div class="padding-y-6" />
    <manage-buttons @cancel="cancel" @save="save" />
  </div>
</template>

<script>
import AdminApi from '@/apis/AdminApi'
import SizeEditor from '@/components/modals/SizeEditor'
import WeightEditor from '@/components/modals/WeightEditor'
import ManageButtons from '@/components/navigation/ManageButtons'
import { SnackbarProgrammatic as Snackbar } from 'buefy'

import Vue2Filters from 'vue2-filters'

export default {
  components: {
    ManageButtons
  },
  mixins: [Vue2Filters.mixin],
  data () {
    return {
      connection: {
        name: null,
        images: [],
        sizes: [],
        royaltyAvailability: 1,
        datasheetAvailability: 1,
        gaugeAvailability: 1,
        blankingAvailability: 1,
        technicalDocsAvailability: 1,
        type: null
      },
      newSize: null,
      sizeExists: false,
      editingSize: null,
      managingWeight: null,
      openedSizeRow: [],
      addSizeToggle: false,
      loaded: false
    }
  },
  computed: {
    id () {
      return this.$route.params.id
    },
    profile () {
      return this.$store.state.profile
    },
    sizesFiltered () {
      return this.connection.sizes.filter(s => !s.deleted)
    }
  },
  async mounted () {
    if (this.id === 'new') {
      this.$store.commit('setTitle', 'New Connection')
    } else {
      await AdminApi.getConnectionById(this.id).then(async connection => {
        this.connection = connection
        // Check the new Graph image
        this.checkNewImages().then(async hasNewImages => {
          if (hasNewImages) {
            // Call again to load the new images
            await AdminApi.getConnectionById(this.id).then(connectionImage => {
              this.connection = connectionImage
            })
          }
        })
      })

      this.$store.commit('setTitle', 'Connection: ' + this.connection.name)
    }

    // this.$validator.extend('validateSize', {
    //   validate: async value => {
    //     try {
    //       if (!value) {
    //         this.$buefy.toast.open({
    //           message: 'Missing royalty values',
    //           type: 'is-danger'
    //         })
    //         return false
    //       }else{
    //         return true
    //       }
    //     } catch (err) {
    //       return false
    //     }
    //   }
    // })

    this.loaded = true
  },
  methods: {
    getRenderImg (id) {
      // determine new connection render image
      const tncConnections = [1, 2, 3, 4, 6, 7, 8]
      if (tncConnections.includes(id)) {
        return require('@/assets/images/connection_type_renderings/Tube_and_Coupling.jpg')
      } else if (id === 9) {
        return require('@/assets/images/connection_type_renderings/Plain_End.jpg')
      } else if (id === 10) {
        return require('@/assets/images/connection_type_renderings/Semiflush.jpg')
      }
    },
    validations (value) {
      // https://stackoverflow.com/questions/50128560/how-to-write-a-custom-generic-validation-for-the-input-fields-using-vee-valida
      if ((!value) && (value !== 0)) {
        this.$buefy.toast.open({
          message: 'Missing royalty values',
          type: 'is-success'
        })
      }
      return { required: true }
    },
    async checkNewImages () {
      var hasNewImage = false
      // This function exist to check if Graph images are into the connection
      var imageGraph = this.connection.images.find(i => i.name === 'Graph')

      if (!imageGraph) {
        imageGraph = await AdminApi.createConnectionImage(this.connection.id, {
          name: 'Graph'
        })
        hasNewImage = true
      }

      return hasNewImage
    },
    async save () {
      if (await this.$validator.validateAll()) {
        const loadingComponent = this.$buefy.loading.open({ container: null })

        if (this.id === 'new') {
          this.connection = await AdminApi.createConnection(this.connection)
          this.$router.push({ name: 'manage_connections' })
        } else {
          await AdminApi.updateConnection(
            this.id,
            this.connection
          ).then(async () => {
            await AdminApi.getConnectionById(this.id).then(async connection => {
              this.connection = connection
            })
          })
        }

        loadingComponent.close()

        this.$buefy.toast.open({
          message: this.connection.name + ' saved',
          type: 'is-success'
        })
      }
    },
    async cancel () {
      this.$router.push({ name: 'manage_connections' })
    },
    addSize () {
      var size = this.sizesFiltered.find(s => {
        if (Number(s.size) === Number(this.newSize)) {
          this.sizeExists = true
          return true
        } else {
          this.sizeExists = false
        }
      })

      if (!size) {
        size = {} // Starts the new object
        size.id = 'new'
        size.connectionId = this.connection.id
        size.size = this.newSize
        size.deleted = false
        size.priceManCra = 0
        size.priceManNon = 0
        size.priceRepCra = 0
        size.priceRepNon = 0
        size.priceAccCra = 0
        size.priceAccNon = 0
        size.weights = []

        this.connection.sizes.unshift(size)
        this.editingSize = size
        this.newSize = null
        this.addSizeToggle = false
      }
    },
    editSize (size) {
      this.editingSize = size
    },
    toggleSizesRow (row) {
      this.$refs.sizesTable.toggleDetails(row)
    },
    closeOtherRows (row, index) {
      this.openedSizeRow = [row.size]
    },
    manageWeights (size) {
      this.managingWeight = size
    },
    initialWeight () {
      var weightInitial = {}

      weightInitial.id = 'new'
      weightInitial.sizeId = null
      weightInitial.weight = null
      weightInitial.pipeBodyWall = null
      weightInitial.couplingId = null
      weightInitial.couplingLength = null
      weightInitial.makeupLoss = null
      weightInitial.spDriftDiameter = null
      weightInitial.couplingEntryId = null
      weightInitial.criticalDiameter = null
      weightInitial.taper = null
      weightInitial.sd = null
      weightInitial.trueTe = null

      /* API Modified */
      weightInitial.apiCarbonKsi55FtMin = null
      weightInitial.apiCarbonKsi55FtMax = null
      weightInitial.apiCarbonKsi55FtOpt = null
      weightInitial.apiCarbonKsi55StMin = null
      weightInitial.apiCarbonKsi55StMax = null
      weightInitial.apiCarbonKsi55Mts = null
      weightInitial.apiCarbonKsi75FtMin = null
      weightInitial.apiCarbonKsi75FtMax = null
      weightInitial.apiCarbonKsi75FtOpt = null
      weightInitial.apiCarbonKsi75StMin = null
      weightInitial.apiCarbonKsi75StMax = null
      weightInitial.apiCarbonKsi75Mts = null
      weightInitial.apiCarbonKsi80FtMin = null
      weightInitial.apiCarbonKsi80FtMax = null
      weightInitial.apiCarbonKsi80FtOpt = null
      weightInitial.apiCarbonKsi80StMin = null
      weightInitial.apiCarbonKsi80StMax = null
      weightInitial.apiCarbonKsi80Mts = null
      weightInitial.apiCarbonKsi85FtMin = null
      weightInitial.apiCarbonKsi85FtMax = null
      weightInitial.apiCarbonKsi85FtOpt = null
      weightInitial.apiCarbonKsi85StMin = null
      weightInitial.apiCarbonKsi85StMax = null
      weightInitial.apiCarbonKsi85Mts = null
      weightInitial.apiCarbonKsi90FtMin = null
      weightInitial.apiCarbonKsi90FtMax = null
      weightInitial.apiCarbonKsi90FtOpt = null
      weightInitial.apiCarbonKsi90StMin = null
      weightInitial.apiCarbonKsi90StMax = null
      weightInitial.apiCarbonKsi90Mts = null
      weightInitial.apiCarbonKsi95FtMin = null
      weightInitial.apiCarbonKsi95FtMax = null
      weightInitial.apiCarbonKsi95FtOpt = null
      weightInitial.apiCarbonKsi95StMin = null
      weightInitial.apiCarbonKsi95StMax = null
      weightInitial.apiCarbonKsi95Mts = null
      weightInitial.apiCarbonKsi100FtMin = null
      weightInitial.apiCarbonKsi100FtMax = null
      weightInitial.apiCarbonKsi100FtOpt = null
      weightInitial.apiCarbonKsi100StMin = null
      weightInitial.apiCarbonKsi100StMax = null
      weightInitial.apiCarbonKsi100Mts = null
      weightInitial.apiCarbonKsi105FtMin = null
      weightInitial.apiCarbonKsi105FtMax = null
      weightInitial.apiCarbonKsi105FtOpt = null
      weightInitial.apiCarbonKsi105StMin = null
      weightInitial.apiCarbonKsi105StMax = null
      weightInitial.apiCarbonKsi105Mts = null
      weightInitial.apiCarbonKsi110FtMin = null
      weightInitial.apiCarbonKsi110FtMax = null
      weightInitial.apiCarbonKsi110FtOpt = null
      weightInitial.apiCarbonKsi110StMin = null
      weightInitial.apiCarbonKsi110StMax = null
      weightInitial.apiCarbonKsi110Mts = null
      weightInitial.apiCarbonKsi125FtMin = null
      weightInitial.apiCarbonKsi125FtMax = null
      weightInitial.apiCarbonKsi125FtOpt = null
      weightInitial.apiCarbonKsi125StMin = null
      weightInitial.apiCarbonKsi125StMax = null
      weightInitial.apiCarbonKsi125Mts = null
      weightInitial.apiCarbonKsi140FtMin = null
      weightInitial.apiCarbonKsi140FtMax = null
      weightInitial.apiCarbonKsi140FtOpt = null
      weightInitial.apiCarbonKsi140StMin = null
      weightInitial.apiCarbonKsi140StMax = null
      weightInitial.apiCarbonKsi140Mts = null

      /* Clear-Run */
      weightInitial.clrCarbonKsi55FtMin = null
      weightInitial.clrCarbonKsi55FtMax = null
      weightInitial.clrCarbonKsi55FtOpt = null
      weightInitial.clrCarbonKsi55StMin = null
      weightInitial.clrCarbonKsi55StMax = null
      weightInitial.clrCarbonKsi55Mts = null
      weightInitial.clrCarbonKsi75FtMin = null
      weightInitial.clrCarbonKsi75FtMax = null
      weightInitial.clrCarbonKsi75FtOpt = null
      weightInitial.clrCarbonKsi75StMin = null
      weightInitial.clrCarbonKsi75StMax = null
      weightInitial.clrCarbonKsi75Mts = null
      weightInitial.clrCarbonKsi80FtMin = null
      weightInitial.clrCarbonKsi80FtMax = null
      weightInitial.clrCarbonKsi80FtOpt = null
      weightInitial.clrCarbonKsi80StMin = null
      weightInitial.clrCarbonKsi80StMax = null
      weightInitial.clrCarbonKsi80Mts = null
      weightInitial.clrCarbonKsi85FtMin = null
      weightInitial.clrCarbonKsi85FtMax = null
      weightInitial.clrCarbonKsi85FtOpt = null
      weightInitial.clrCarbonKsi85StMin = null
      weightInitial.clrCarbonKsi85StMax = null
      weightInitial.clrCarbonKsi85Mts = null
      weightInitial.clrCarbonKsi90FtMin = null
      weightInitial.clrCarbonKsi90FtMax = null
      weightInitial.clrCarbonKsi90FtOpt = null
      weightInitial.clrCarbonKsi90StMin = null
      weightInitial.clrCarbonKsi90StMax = null
      weightInitial.clrCarbonKsi90Mts = null
      weightInitial.clrCarbonKsi95FtMin = null
      weightInitial.clrCarbonKsi95FtMax = null
      weightInitial.clrCarbonKsi95FtOpt = null
      weightInitial.clrCarbonKsi95StMin = null
      weightInitial.clrCarbonKsi95StMax = null
      weightInitial.clrCarbonKsi95Mts = null
      weightInitial.clrCarbonKsi100FtMin = null
      weightInitial.clrCarbonKsi100FtMax = null
      weightInitial.clrCarbonKsi100FtOpt = null
      weightInitial.clrCarbonKsi100StMin = null
      weightInitial.clrCarbonKsi100StMax = null
      weightInitial.clrCarbonKsi100Mts = null
      weightInitial.clrCarbonKsi105FtMin = null
      weightInitial.clrCarbonKsi105FtMax = null
      weightInitial.clrCarbonKsi105FtOpt = null
      weightInitial.clrCarbonKsi105StMin = null
      weightInitial.clrCarbonKsi105StMax = null
      weightInitial.clrCarbonKsi105Mts = null
      weightInitial.clrCarbonKsi110FtMin = null
      weightInitial.clrCarbonKsi110FtMax = null
      weightInitial.clrCarbonKsi110FtOpt = null
      weightInitial.clrCarbonKsi110StMin = null
      weightInitial.clrCarbonKsi110StMax = null
      weightInitial.clrCarbonKsi110Mts = null
      weightInitial.clrCarbonKsi125FtMin = null
      weightInitial.clrCarbonKsi125FtMax = null
      weightInitial.clrCarbonKsi125FtOpt = null
      weightInitial.clrCarbonKsi125StMin = null
      weightInitial.clrCarbonKsi125StMax = null
      weightInitial.clrCarbonKsi125Mts = null
      weightInitial.clrCarbonKsi140FtMin = null
      weightInitial.clrCarbonKsi140FtMax = null
      weightInitial.clrCarbonKsi140FtOpt = null
      weightInitial.clrCarbonKsi140StMin = null
      weightInitial.clrCarbonKsi140StMax = null
      weightInitial.clrCarbonKsi140Mts = null

      /* Chrome */
      // Api
      // weightInitial.apiChromeKsi55FtMin = null
      // weightInitial.apiChromeKsi55FtMax = null
      // weightInitial.apiChromeKsi55FtOpt = null
      // weightInitial.apiChromeKsi55StMin = null
      // weightInitial.apiChromeKsi55StMax = null
      // weightInitial.apiChromeKsi55Mts = null
      weightInitial.apiChromeKsi75FtMin = null
      weightInitial.apiChromeKsi75FtMax = null
      weightInitial.apiChromeKsi75FtOpt = null
      weightInitial.apiChromeKsi75StMin = null
      weightInitial.apiChromeKsi75StMax = null
      weightInitial.apiChromeKsi75Mts = null
      weightInitial.apiChromeKsi80FtMin = null
      weightInitial.apiChromeKsi80FtMax = null
      weightInitial.apiChromeKsi80FtOpt = null
      weightInitial.apiChromeKsi80StMin = null
      weightInitial.apiChromeKsi80StMax = null
      weightInitial.apiChromeKsi80Mts = null
      weightInitial.apiChromeKsi85FtMin = null
      weightInitial.apiChromeKsi85FtMax = null
      weightInitial.apiChromeKsi85FtOpt = null
      weightInitial.apiChromeKsi85StMin = null
      weightInitial.apiChromeKsi85StMax = null
      weightInitial.apiChromeKsi85Mts = null
      weightInitial.apiChromeKsi90FtMin = null
      weightInitial.apiChromeKsi90FtMax = null
      weightInitial.apiChromeKsi90FtOpt = null
      weightInitial.apiChromeKsi90StMin = null
      weightInitial.apiChromeKsi90StMax = null
      weightInitial.apiChromeKsi90Mts = null
      weightInitial.apiChromeKsi95FtMin = null
      weightInitial.apiChromeKsi95FtMax = null
      weightInitial.apiChromeKsi95FtOpt = null
      weightInitial.apiChromeKsi95StMin = null
      weightInitial.apiChromeKsi95StMax = null
      weightInitial.apiChromeKsi95Mts = null
      weightInitial.apiChromeKsi100FtMin = null
      weightInitial.apiChromeKsi100FtMax = null
      weightInitial.apiChromeKsi100FtOpt = null
      weightInitial.apiChromeKsi100StMin = null
      weightInitial.apiChromeKsi100StMax = null
      weightInitial.apiChromeKsi100Mts = null
      weightInitial.apiChromeKsi105FtMin = null
      weightInitial.apiChromeKsi105FtMax = null
      weightInitial.apiChromeKsi105FtOpt = null
      weightInitial.apiChromeKsi105StMin = null
      weightInitial.apiChromeKsi105StMax = null
      weightInitial.apiChromeKsi105Mts = null
      weightInitial.apiChromeKsi110FtMin = null
      weightInitial.apiChromeKsi110FtMax = null
      weightInitial.apiChromeKsi110FtOpt = null
      weightInitial.apiChromeKsi110StMin = null
      weightInitial.apiChromeKsi110StMax = null
      weightInitial.apiChromeKsi110Mts = null
      weightInitial.apiChromeKsi125FtMin = null
      weightInitial.apiChromeKsi125FtMax = null
      weightInitial.apiChromeKsi125FtOpt = null
      weightInitial.apiChromeKsi125StMin = null
      weightInitial.apiChromeKsi125StMax = null
      weightInitial.apiChromeKsi125Mts = null
      weightInitial.apiChromeKsi140FtMin = null
      weightInitial.apiChromeKsi140FtMax = null
      weightInitial.apiChromeKsi140FtOpt = null
      weightInitial.apiChromeKsi140StMin = null
      weightInitial.apiChromeKsi140StMax = null
      weightInitial.apiChromeKsi140Mts = null
      // Clear run
      // weightInitial.clrChromeKsi55FtMin = null
      // weightInitial.clrChromeKsi55FtMax = null
      // weightInitial.clrChromeKsi55FtOpt = null
      // weightInitial.clrChromeKsi55StMin = null
      // weightInitial.clrChromeKsi55StMax = null
      // weightInitial.clrChromeKsi55Mts = null
      weightInitial.clrChromeKsi75FtMin = null
      weightInitial.clrChromeKsi75FtMax = null
      weightInitial.clrChromeKsi75FtOpt = null
      weightInitial.clrChromeKsi75StMin = null
      weightInitial.clrChromeKsi75StMax = null
      weightInitial.clrChromeKsi75Mts = null
      weightInitial.clrChromeKsi80FtMin = null
      weightInitial.clrChromeKsi80FtMax = null
      weightInitial.clrChromeKsi80FtOpt = null
      weightInitial.clrChromeKsi80StMin = null
      weightInitial.clrChromeKsi80StMax = null
      weightInitial.clrChromeKsi80Mts = null
      weightInitial.clrChromeKsi85FtMin = null
      weightInitial.clrChromeKsi85FtMax = null
      weightInitial.clrChromeKsi85FtOpt = null
      weightInitial.clrChromeKsi85StMin = null
      weightInitial.clrChromeKsi85StMax = null
      weightInitial.clrChromeKsi85Mts = null
      weightInitial.clrChromeKsi90FtMin = null
      weightInitial.clrChromeKsi90FtMax = null
      weightInitial.clrChromeKsi90FtOpt = null
      weightInitial.clrChromeKsi90StMin = null
      weightInitial.clrChromeKsi90StMax = null
      weightInitial.clrChromeKsi90Mts = null
      weightInitial.clrChromeKsi95FtMin = null
      weightInitial.clrChromeKsi95FtMax = null
      weightInitial.clrChromeKsi95FtOpt = null
      weightInitial.clrChromeKsi95StMin = null
      weightInitial.clrChromeKsi95StMax = null
      weightInitial.clrChromeKsi95Mts = null
      weightInitial.clrChromeKsi100FtMin = null
      weightInitial.clrChromeKsi100FtMax = null
      weightInitial.clrChromeKsi100FtOpt = null
      weightInitial.clrChromeKsi100StMin = null
      weightInitial.clrChromeKsi100StMax = null
      weightInitial.clrChromeKsi100Mts = null
      weightInitial.clrChromeKsi105FtMin = null
      weightInitial.clrChromeKsi105FtMax = null
      weightInitial.clrChromeKsi105FtOpt = null
      weightInitial.clrChromeKsi105StMin = null
      weightInitial.clrChromeKsi105StMax = null
      weightInitial.clrChromeKsi105Mts = null
      weightInitial.clrChromeKsi110FtMin = null
      weightInitial.clrChromeKsi110FtMax = null
      weightInitial.clrChromeKsi110FtOpt = null
      weightInitial.clrChromeKsi110StMin = null
      weightInitial.clrChromeKsi110StMax = null
      weightInitial.clrChromeKsi110Mts = null
      weightInitial.clrChromeKsi125FtMin = null
      weightInitial.clrChromeKsi125FtMax = null
      weightInitial.clrChromeKsi125FtOpt = null
      weightInitial.clrChromeKsi125StMin = null
      weightInitial.clrChromeKsi125StMax = null
      weightInitial.clrChromeKsi125Mts = null
      weightInitial.clrChromeKsi140FtMin = null
      weightInitial.clrChromeKsi140FtMax = null
      weightInitial.clrChromeKsi140FtOpt = null
      weightInitial.clrChromeKsi140StMin = null
      weightInitial.clrChromeKsi140StMax = null
      weightInitial.clrChromeKsi140Mts = null
      /* Coupling */
      weightInitial.couplings = []

      return weightInitial
    },
    async sizeModal (size) {
      this.$buefy.modal.open({
        parent: this,
        component: SizeEditor,
        props: {
          size: size,
          sizes: this.sizesFiltered
        },
        events: {
          change: async (
            size
          ) => {
            if (this.id !== 'new') {
              // If is not new, system needs save all temp sizes created
              this.save()
            }
          },
          delete: async (size) => {
            this.goDeleteSize(size)
          }
        }
      })
    },
    async weightModal (size, weight) {
      // get visible grades column to pass down as prop for grade visibility
      const res = await AdminApi.getGradeVisibiltyColumn(weight.id)
      let hiddenGrades = []

      if (res.hiddenGrades) {
        hiddenGrades = res.hiddenGrades.split(',')
      }

      for (let i = 0; i < hiddenGrades.length; i++) {
        // convert char nums into ints
        hiddenGrades[i] = parseInt(hiddenGrades[i])
      }

      const resSpDrifts = await AdminApi.getGradeSpDriftVisibiltyColumn(weight.id)
      let showSpDrifts = []

      if (resSpDrifts.showSpDrifts) {
        showSpDrifts = resSpDrifts.showSpDrifts.split(',')
      }

      for (let i = 0; i < showSpDrifts.length; i++) {
        // convert char nums into ints
        showSpDrifts[i] = parseInt(showSpDrifts[i])
      }

      weight.sizeId = size.id
      this.$buefy.modal.open({
        parent: this,
        component: WeightEditor,
        props: {
          size: size,
          weight: weight,
          hiddenGrades: hiddenGrades,
          showSpDrifts: showSpDrifts
        },
        events: {
          change: async (
            sizeData,
            torqueDataCarbon,
            torqueDataChrome,
            couplingData,
            blankDimensionsData,
            inspectionFields
          ) => {
            var weightIndex = size.weights.find(
              w => w.weight === weight.weight
            )

            if (!weightIndex) {
              size.weights.push(weight)
            }

            if (this.id !== 'new') {
              this.save()
            }
          },
          delete: async sizeData => {
            var weightIndex = size.weights.indexOf(
              size.weights.find(w => w.weight === weight.weight)
            )
            if (weightIndex !== -1) {
              size.weights.splice(weightIndex, 1)
            }
            if (weight.id !== 'new') {
              weight.deleted = true
              await AdminApi.deleteConnectionSizeWeightById(weight.id)
            }
          }
        }
      })
    },
    deleteSize (size) {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to delete this size?',
        confirmText: 'Delete Size',
        type: 'is-danger',
        onConfirm: () => {
          this.goDeleteSize(size)
        }
      })
    },
    async goDeleteSize (size) {
      var sizeIndex = this.connection.sizes.indexOf(
        this.connection.sizes.find(
          s => s.size === size.size && s.id === 'new'
        )
      )
      if (sizeIndex !== -1) {
        this.connection.sizes.splice(sizeIndex, 1)
      }
      if (size.id !== 'new') {
        size.deleted = true
        size = await AdminApi.updateConnectionSize(size.id, size)
      }
      this.$buefy.toast.open('Size Deleted')
    },
    async uploadFile (image, file) {
      try {
        const reader = new FileReader()

        if (file.size < 1000000) {
          reader.onload = () => {
            image.type = file.type
            image.data = reader.result
          }

          reader.readAsDataURL(file)
        } else {
          this.errors.items.push('imageUp')
          Snackbar.open({
            message: 'File size should be less than 1MB',
            type: 'is-warning',
            position: 'is-top'
          })
          await this.$validator.validateAll()
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
